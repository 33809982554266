import React, { useState, useEffect } from 'react'
import "./banner.scss"
import Header from '../Header/Header';
import { DateRange } from "react-date-range";
import axios from 'axios';
import moment from 'moment/moment';
import useWeb3 from '../../../hooks/useWeb3';
import { getUsdcContract ,getDopContract} from '../../../utils/contractHelpers';
import Environment from '../../../utils/Enviroment';
import ValueLockedGraph from './ValueLockedGraph';
import Countrychart from './CountryChart';
import Datatable from './Datatable/Datatable';
import getNodeUrl from '../../../utils/getRpcUrl';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const [state, setState] = useState({ startDate: new Date(), endDate: new Date(), key: 'selection' });
  const [filterDates, setFilterDates] = useState({ startDate: new Date(), endDate: new Date() });
  const api_url = Environment.api_url;
  const token = localStorage.getItem("accessToken");
  const [alllset, setalllset] = useState("1");
  const [dataTime, setDataTime] = useState("1");
  const [showcalendar, setShowCalendar] = useState(false);
  const [admindata, setAdminData] = useState([]);
  const [usersdata, setUsersData] = useState([]);
  const [usersencrypt, setUsersEncrypts] = useState([]);
  const [userssends, setUsersSends] = useState([]);
  const [userspoints, setUsersPoints] = useState([]);
  const [countries, setCountries] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [tvlgraph, setTvlGraph] = useState([]);
  const [countriesgraph, setCountriesGraph] = useState([]);
  const [decryptData, setDecryptData] = useState(null);
  const [encryptData, setEncryptData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loadertvl, setLoaderTvl] = useState(false);
  const [loadertransaction, setLoaderTransaction] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const web3 = useWeb3();
  const nodeurl = getNodeUrl()
  const navigate = useNavigate()
  const stakingAddress1 = Environment.Usdc;
  const stakingAddress2 = Environment.Usdt;
  const stakingAddress3 = Environment.Wbth;
  const stakingAddress4 = Environment.Weth;
  const stakingAddress5 = Environment.Dop;
  const contract1 = getUsdcContract(stakingAddress1, web3);
  const contract2 = getUsdcContract(stakingAddress2, web3);
  const contract3 = getUsdcContract(stakingAddress3, web3);
  const contract4 = getUsdcContract(stakingAddress4, web3);
  const contract5 = getDopContract(stakingAddress5, web3);



  const getAdminData = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;

      try {
        const response = await axios.get(`${api_url}/admins/admin-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdminData(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencers:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: new Date(today),
        endDate: new Date(today),
      },
    ];
    getAdminData(specificDateRange, state);
  }, []);
  let address = "0x9A3152b61420ed4D5e594c0b48bB932eE41B7376"
  const [usdtvalue, getUsdtValue] = useState(0)
  const [wethvalue, getWethValue] = useState(0)
  const [wbthvalue, getWbthValue] = useState(0)
  const [usdcvalue, getUsdcValue] = useState(0)
  const [dopvalue, getDopValue] = useState(0)
  const [sumofall, getSumofall] = useState(0)


  useEffect(() => {
    if (!usdtvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=tether")
        .then((res) => {
          getUsdtValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!wethvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=ethereum")
        .then((res) => {
          getWethValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!wbthvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=wrapped-bitcoin")
        .then((res) => {
          getWbthValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!usdcvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=usd-coin")
        .then((res) => {
          getUsdcValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!dopvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=data-ownership-protocol")
        .then((res) => {
          getDopValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
  }, []);


  const getPlatformFee = async () => {
    try {
      const [staked1, staked2, staked3, staked4,staked5] = await Promise.all([
        contract1?._methods?.balanceOf(address)?.call(),
        contract2?._methods?.balanceOf(address)?.call(),
        contract3?._methods?.balanceOf(address)?.call(),
        contract4?._methods?.balanceOf(address)?.call(),
        contract5?._methods?.balanceOf(address)?.call(),
      ]);
      const staked1Converted = web3.utils.fromWei(staked1, 'mwei'); // 6 decimals for USDC
      const staked2Converted = web3.utils.fromWei(staked2, 'mwei'); // 6 decimals for USDT
      const staked3Converted = web3.utils.fromWei(staked3, 'gwei'); // 8 decimals for WBTH
      const staked4Converted = web3.utils.fromWei(staked4, 'ether'); // 18 decimals for WETH
      const staked5Converted = web3.utils.fromWei(staked5, 'ether'); 
      let finalsum = (staked1Converted * usdcvalue) + (staked2Converted * usdtvalue) + (staked3Converted * wbthvalue) + (staked4Converted * wethvalue)  + (staked5Converted * dopvalue)
      getSumofall(finalsum)
    } catch (error) {
      console.error("Error in getPlatformFee:", error);
      throw error;
    }
  };
  useEffect(() => {
    getPlatformFee();
  }, [contract1, contract2, contract3, contract4,contract5]);


  const getUsersData = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/users-socials${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsersData(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencerssss:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, // Today
        endDate: today, // Today
      },
    ];
    getUsersData(specificDateRange, state);
  }, []);

  const getUsersEncrypts = async () => {
    try {
      const response = await axios.get(`${api_url}/admins/users-encrypts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersEncrypts(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUsersEncrypts();
  }, []);
  const getUsersSends = async () => {

    try {
      const response = await axios.get(`${api_url}/admins/users-sends`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersSends(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUsersSends();
  }, []);
  const getUsersPoints = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/users-countries${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountries(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencers:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, // Today
        endDate: today, // Today
      },
    ];
    getUsersPoints(specificDateRange, state);
  }, []);

  const getCountries = async () => {
    try {
      const response = await axios.get(`${api_url}/admins/users-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersPoints(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  const getLatestTransactions = async (times) => {
    setLoaderTransaction(true)
    const { startDate, endDate } = filterDates;
    const formattedStartDate = moment(startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
    const formattedEndDate = moment(endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
    const dynamicQuery = `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/transactions${dynamicQuery}&offset=${`${page}`}&limit=10`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTransactions(response?.data?.data?.transactions);
        setPageCount(response?.data?.data?.count)
        setLoaderTransaction(false)
      } catch (err) {
        console.error('Error fetching influencers:', err);
        if (err.response && err.response.status === 401) {
          navigate('/');
      } else {
          console.error('Error fetching transactions:', err);
      }
      setLoaderTransaction(false);
      }  
  };
  useEffect(() => {
    getLatestTransactions();
  }, [page,filterDates]);

  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);
  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  const getEncryptDecrypt = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-amounts-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDecryptData(response?.data?.data?.decryptData?.[0]);
        setEncryptData(response?.data?.data?.encryptData?.[0]);
      } catch (err) {
        console.error('Error fetching influencers:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, // Today
        endDate: today, // Today
      },
    ];
    getEncryptDecrypt(specificDateRange, state);
  }, []);


  const getTvlGraph = async (times) => {
    setTvlGraph([])
    setLoaderTvl(true)
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;

      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-tvl-graph-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTvlGraph(response?.data?.data);
        setLoaderTvl(false)
      } catch (err) {
        console.error('Error fetching TVL graph data:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, 
        endDate: today, 
      },
    ];
    getTvlGraph(specificDateRange, state);
  }, []);
  const getCountriesGraph = async (times) => {
    setLoader(true);
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-country-graph-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountriesGraph(response?.data?.data);
        setLoader(false);
      } catch (err) {
        console.error('Error fetching influencers:', err);
        setLoader(false); 
      }
    }
  };

  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today,
        endDate: today,
      },
    ];
    getCountriesGraph(specificDateRange, state);
  }, []);

  const formatAmount = (amount) => {
    return Number.isNaN(Number(amount)) ? '0' : Number(amount?.toFixed(4)).toLocaleString();
  };


  let sumofbadges = (usersdata?.discordFollowers + usersdata?.emailVerified + usersdata?.kycVerified + usersdata?.telegramFollowers + usersdata?.twitterFollowers +
    usersencrypt?.encrypt_10000 + usersencrypt?.encrypt_20000 + usersencrypt?.encrypt_50000 + usersencrypt?.encrypt_100000 + usersencrypt?.encrypt_500000 + usersencrypt?.encrypt_1000000 +
    userssends?.send_50 + userssends?.send_100 +
    userssends?.send_200 + userssends?.send_500)

  const handleCalendar = (startDate, endDate) => {
    getAdminData([{ startDate, endDate }]);
    getUsersData([{ startDate, endDate }]);
    getUsersPoints([{ startDate, endDate }]);
    getEncryptDecrypt([{ startDate, endDate }]);
    getLatestTransactions([{ startDate, endDate }]);
    getTvlGraph([{ startDate, endDate }]);
    getCountriesGraph([{ startDate, endDate }]);
    setFilterDates({ startDate, endDate });
    setPage(0);
  }


  return (
    <>
      <Header />
      <section className="main-banner">
        <div className="custom-container">
          <div className="twice-items">
            <h6>Welcome, admin</h6>
            <div className="custom-tab-bar">
              <a
                onClick={() => {
                  // const startDate = moment().startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                  // const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                  const startDate = moment().utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
                  const endDate = moment().utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
                  handleCalendar(startDate, endDate);
                  setalllset("1");
                  setDataTime("1");
                }}
                className={alllset == "1" ? "active" : "noactive"}
              >
                1D
              </a>
              <a
                onClick={() => {
                  const startDate = moment().startOf('day').subtract(6, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                  const endDate = moment().endOf('day').subtract(1, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                  handleCalendar(startDate, endDate);
                  setalllset("seven");
                  setDataTime("seven");
                }}
                className={alllset == "seven" ? "active" : "noactive"}
              >
                7D
              </a>
              <a

                onClick={() => {
                  const startDate = moment().startOf('day').subtract(30, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                  const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                  handleCalendar(startDate, endDate);
                  setalllset("month");
                  setDataTime("month");

                }}
                className={alllset == "month" ? "active" : "noactive"}
              >
                1M
              </a>
              <a
                onClick={() => {
                  const startDate = moment().startOf('day').subtract(365, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                  const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                  handleCalendar(startDate, endDate);
                  setalllset("year");
                  setDataTime("year");
                }}
                className={alllset == "year" ? "active" : "noactive"}
              >
                1Y
              </a>
              <a
                onClick={() => {
                  const startDate = moment().startOf('day').subtract(365, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                  const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                  setalllset("all");
                  setDataTime("");
                  handleCalendar(startDate, endDate)
                }}
                className={alllset == "all" ? "active" : "noactive"}
              >
                All
              </a>
              <a onClick={() => setShowCalendar(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M8.6665 4H7.8667C6.74659 4 6.18612 4 5.7583 4.21799C5.38198 4.40973 5.07624 4.71547 4.88449 5.0918C4.6665 5.51962 4.6665 6.08009 4.6665 7.2002V8M8.6665 4H16.6665M8.6665 4V2M16.6665 4H17.4667C18.5868 4 19.1461 4 19.5739 4.21799C19.9502 4.40973 20.257 4.71547 20.4487 5.0918C20.6665 5.5192 20.6665 6.07899 20.6665 7.19691V8M16.6665 4V2M4.6665 8V16.8002C4.6665 17.9203 4.6665 18.4801 4.88449 18.9079C5.07624 19.2842 5.38198 19.5905 5.7583 19.7822C6.18571 20 6.7455 20 7.86341 20H17.4696C18.5875 20 19.1465 20 19.5739 19.7822C19.9502 19.5905 20.257 19.2842 20.4487 18.9079C20.6665 18.4805 20.6665 17.9215 20.6665 16.8036V8M4.6665 8H20.6665M16.6665 16H16.6685L16.6685 16.002L16.6665 16.002V16ZM12.6665 16H12.6685L12.6685 16.002L12.6665 16.002V16ZM8.6665 16H8.6685L8.66846 16.002L8.6665 16.002V16ZM16.6685 12V12.002L16.6665 12.002V12H16.6685ZM12.6665 12H12.6685L12.6685 12.002L12.6665 12.002V12ZM8.6665 12H8.6685L8.66846 12.002L8.6665 12.002V12Z" stroke="#A2A4A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {showcalendar && (
                  <div className="cal set-custom-calendar-div">
                    <DateRange
                      editableDateInputs={true}
                      moveRangeOnFirstSelection={false}
                      ranges={[state]}
                      onChange={(item) => {
                        const startDate = moment(item?.selection?.startDate).subtract(-2, 'day').utc().format("Y-MM-DDT00:00:00");
                        const endDate = moment(item?.selection?.endDate).subtract(-1, 'day').utc().format("Y-MM-DDT23:59:59");
                        setState(item.selection);
                        handleCalendar(startDate, endDate);
                        if (item.selection.startDate && item.selection.endDate) {
                          setShowCalendar(false);
                        }
                        setalllset("")
                      }}
                    />
                  </div>
                )}
              </a>
            </div>
          </div>
          <div className="bottom-content">
            <div className="mainnet-part">
              <div className="heading">
                <h6>MAINNET STATS</h6>
              </div>
              <div className="bottom-boxes">
                <div className="single-item">
                  <img src="\assets\totalvaluelocked.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Value Locked</p>
                    <h6>
                      {Number(sumofall?.toFixed(2))?.toLocaleString()}
                      {/* <span className='red-text'>-0.6%</span> */}
                    </h6>
                  </div>
                </div>
                <div className="single-item">
                  <img src="\assets\wallet.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Wallets Created</p>
                    <h6>
                      {admindata?.walletCount?.data?.[0]?.count
                        ? Number(admindata?.walletCount?.data[0]?.count?.toFixed(0))?.toLocaleString()
                        : '0'}
                      {/* <span className='red-text'>+5.4%</span> */}
                    </h6>

                  </div>
                </div>
                {/* <div className="single-item">
                  <img src="\assets\appvisitor.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>App Visitors</p>
                    <h6>
                      0
                     
                    </h6>

                  </div>
                </div> */}
                {/* <span className='green-text'>+2.3%</span> */}
                {/* <div className="single-item">
                  <img src="\assets\totaltransactions.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Transactions</p>
                    <h6>
                      {admindata?.totalTransactions?.data?.[0]?.count
                        ? Number(admindata.totalTransactions.data[0].count.toFixed(0)).toLocaleString()
                        : '0'}
                    </h6>

                  </div>
                </div>
                <div className="single-item">
                  <img src="\assets\totalpoints.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Points Earned</p>
                    <h6>
                      {admindata?.totalPoints?.data?.[0]?.count
                        ? Number(admindata.totalPoints.data[0].count.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : '0'}
                    </h6>

                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="bottom-twice-graph">
            <div className="graph-parent">
              <h6 className="main-heading">
                total value locked
              </h6>
              <ValueLockedGraph tvlgraph={tvlgraph} loader={loadertvl} />
            </div>
            <div className="graph-parent">
              <h6 className="main-heading">
                top 10 countries by value locked
              </h6>
              <Countrychart countriesgraph={countriesgraph} loader={loader} />
            </div>
          </div>
          <div className="bottom-twice-box">
            <div className="single-box">
              <div className="main-head">
                <h6>Encryptions</h6>
              </div>
              <div className="inside-box">
                <div className="upper-text">
                  <div className="inner-text">
                    <p>Amount Encrypted</p>
                    <h6> ${Number.isNaN(Number(encryptData?.overallTotal))
                      ? "0"
                      : Number(encryptData?.overallTotal?.toFixed(2))?.toLocaleString()}
                      {/* <span className='red-text'>-1.3%</span> */}
                    </h6>
                  </div>
                  <div class="dropdown">
                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M16 10.1437L12 14.1437L8 10.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      {/* <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\eth.svg" alt="img" className='img-fluid' />
                            <p>ETH</p>
                          </div>
                          <h6>0 ETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>0 WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>0 WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>0 USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>0 USDC</h6>
                        </div>
                      </div> */}
                      <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'ethereum')?.totalAmount)} WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'wrapped-bitcoin')?.totalAmount)} WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'tether')?.totalAmount)} USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'usd-coin')?.totalAmount)} USDC</h6>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>No. of Encrypts</p>
                  <h6>
                    {/* {encryptData?.totalCount} */}
                    {Number.isNaN(Number(encryptData?.totalCount))
                      ? "0"
                      : Number(encryptData?.totalCount?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="main-head">
                <h6>decryptions</h6>
              </div>
              <div className="inside-box">
                <div className="upper-text">
                  <div className="inner-text">
                    <p>Amount Decrypted</p>
                    <h6>
                      ${Number.isNaN(Number(decryptData?.overallTotal))
                        ? "0"
                        : Number(decryptData?.overallTotal?.toFixed(2))?.toLocaleString()}
                      {/* <span className='red-text'>-1.3%</span> */}
                    </h6>
                  </div>
                  <div class="dropdown">
                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M16 10.1437L12 14.1437L8 10.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      {/* <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\eth.svg" alt="img" className='img-fluid' />
                            <p>ETH</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}ETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.assets?.[2]?.totalAmount))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.assets?.[2]?.totalAmount)}WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}USDC</h6>
                        </div>
                      </div> */}
                      <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'ethereum')?.totalAmount)} WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'wrapped-bitcoin')?.totalAmount)} WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'tether')?.totalAmount)} USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'usd-coin')?.totalAmount)} USDC</h6>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>No. of  Decrypts</p>
                  <h6>
                    {/* {decryptData?.totalCount} */}
                    {Number.isNaN(Number(decryptData?.totalCount))
                      ? "0"
                      : Number(decryptData?.totalCount?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="reward-part">
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\pointsearned.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Points Earned</p>
                  <h6>
                    {Number.isNaN(Number(userspoints?.totalPoints))
                      ? "0"
                      : Number(userspoints?.totalPoints?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <rect x="24.5" y="24.1437" width="24" height="24" rx="12" transform="rotate(-180 24.5 24.1437)" fill="white" fill-opacity="0.1" />
                    <path d="M8.5 14.1437L12.5 10.1437L16.5 14.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <div className="inside-coins">
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Action Points</p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalActionPoints))
                        ? "0"
                        : Number(userspoints?.totalActionPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Referral Points</p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalReferralPoints))
                        ? "0"
                        : Number(userspoints?.totalReferralPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Staking Points </p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalStakingPoints))
                        ? "0"
                        : Number(userspoints?.totalStakingPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>

                  </div>
                </ul>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\totalrefferal.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Total Referrals</p>
                  <h6>
                    {Number.isNaN(Number(userspoints?.referalsCount))
                      ? "0"
                      : Number(userspoints?.referalsCount?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\totalcountries.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Total Countries</p>
                  <h6>{countries?.countries}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\badgeearned.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Badges Earned</p>
                  <h6>
                    {Number.isNaN(Number(sumofbadges))
                      ? "0"
                      : Number(sumofbadges?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
              <div class="dropdown dropdownbadges">
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <rect x="24.5" y="24.1437" width="24" height="24" rx="12" transform="rotate(-180 24.5 24.1437)" fill="white" fill-opacity="0.1" />
                    <path d="M8.5 14.1437L12.5 10.1437L16.5 14.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <div className="inside-coins">
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\telgram.svg" alt="img" className='img-fluid' />
                        <p>Join Telegram</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.telegramFollowers))
                          ? "0"
                          : Number(usersdata?.telegramFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\twitter.svg" alt="img" className='img-fluid' />
                        <p>Follow X</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.twitterFollowers))
                          ? "0"
                          : Number(usersdata?.twitterFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\discord.svg" alt="img" className='img-fluid' />
                        <p>Follow Discord</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.discordFollowers))
                          ? "0"
                          : Number(usersdata?.discordFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\zkkyc.svg" alt="img" className='img-fluid' />
                        <p>Zk KYC</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersdata?.kycVerified))
                          ? "0"
                          : Number(usersdata?.kycVerified?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\email.svg" alt="img" className='img-fluid' />
                        <p>Email verification</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersdata?.emailVerified))
                          ? "0"
                          : Number(usersdata?.emailVerified?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e1.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $10,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_10000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_10000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e2.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $20,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_20000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_20000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e3.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $50,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_50000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_50000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e4.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $100,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_100000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_100000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e5.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $500,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_500000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_500000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e6.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $1,000,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_1000000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_1000000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s1.svg" alt="img" className='img-fluid' />
                        <p>50 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_50))
                          ? "0"
                          : Number(userssends?.send_50?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s2.svg" alt="img" className='img-fluid' />
                        <p>100 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_100))
                          ? "0"
                          : Number(userssends?.send_100?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s3.svg" alt="img" className='img-fluid' />
                        <p>200 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_200))
                          ? "0"
                          : Number(userssends?.send_200?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s4.svg" alt="img" className='img-fluid' />
                        <p>500 Sends</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(userssends?.send_500))
                          ? "0"
                          : Number(userssends?.send_500?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds1.svg" alt="img" className='img-fluid' />
                        <p>DOP Prince</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds2.svg" alt="img" className='img-fluid' />
                        <p>DOP Queen</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds3.svg" alt="img" className='img-fluid' />
                        <p>DOP King</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds4.svg" alt="img" className='img-fluid' />
                        <p>DOP Treasure</p>
                      </div>
                      <h6>0</h6>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <Datatable page={page} pageCount={pageCount} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} loader={loadertransaction} transactions={transactions} />
        </div>
      </section >
    </>
  )
}

export default Banner