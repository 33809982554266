let Environment = {
  api_url: "https://mainnet-api-prod.dop.org",  
  // api_url: "http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8082",
  // api_url: ""https://mainnet-api.dop.org",
  Usdc:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  Usdt:'0xdAC17F958D2ee523a2206206994597C13D831ec7',
  Weth:'0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  Wbth:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  Dop:"0x97a9a15168c22b3c137e6381037e1499c8ad0978"

};

export default Environment;
